import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import {
  Card,
  Typography,
  Button,
  makeStyles,
  useMediaQuery,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Box,
  CardContent,
  Tabs,
  Tab,
} from '@material-ui/core';

import 'react-calendar/dist/Calendar.css';
import { ICoupon } from 'src/types';

import giftbox from 'src/assets/giftbox.png';
import radio from 'src/assets/Radio.png';
import radioselected from 'src/assets/Radioselected.png';
import installment from 'src/assets/installment.png';
import shield from 'src/assets/Shield.png';
import women from 'src/assets/african-american-woman.png';
import money from 'src/assets/image-money.png';
import waterDrop from 'src/assets/Water-drop.svg';
import discount from 'src/assets/Discount.svg';
import phonelinkring from 'src/assets/Phonelink-ring.svg';
import vector from 'src/assets/Vector.svg';
import autotowing from 'src/assets/Auto-towing.svg';
import semiColumn from 'src/assets/semi-column.svg';
import arrowup from 'src/assets/Arrow-3.svg';

import mixPanel from 'src/utils/mixpanel';
import { MIXPANEL_TRACK_FUNNEL_V2 } from 'src/utils/consts';
import { updateEstimateServiceStatusV2 } from 'src/api/quote';

import { setAppointment } from 'src/store/actions';

import {
  ArrowBack,
  ArrowForward,
  CheckCircle,
  ExpandMore,
  ToggleOff,
  ToggleOn,
} from '@material-ui/icons';
import InvoiceMobile from '../atoms/InvoiceMobile';
import ReferralBox from '../atoms/ReferralCode';
import GetBackStep from './GetBackStep';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    background: '#fff',
    '& .MuiAccordion-root:before': {
      height: 0,
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: '50px',
    fontFamily: 'inter',
    position: 'fixed',
  },
  continueButton: {
    marginLeft: theme.spacing(1),
  },
  timePickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    width: '100%',
    maxHeight: 320,
    margin: 'auto',
    overflowY: 'scroll',
    [theme.breakpoints.up('md')]: {
      width: '300px',
    },
  },
  verticalInputs: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > *': {
        marginBottom: 10,
      },
    },
  },
  itemTimeSlot: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    borderRadius: 8,
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 500,
    color: '#7E7A92',

    background: theme.palette.common.white,

    '&.selected': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  buttonForward: {
    color: '#fff',
    margin: '0px 20px 20px 20px',
    padding: '6px 24px',
    fontFamily: 'inter',
    borderRadius: '32px',
    textTransform: 'none',
  },
  greenCard: {
    backgroundColor: '#005959',
    padding: '24px 16px',
    flex: 1,
    color: '#fff',
  },
  whiteCard: {
    backgroundColor: theme.palette.common.white,
  },
  mobileRoot: {
    width: '100%',
  },
  TimeandLocation: {
    marginTop: 20,

    paddingBottom: 20,
    background: '#fff',
    marginBottom: 0,
    maxWidth: 350,
    width: '100%',
  },
  accordion: {
    '& .MuiAccordion-root:before': {
      height: 0,
    },
  },

  radioGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  Hdiv: {
    display: 'flex',
    flexDirection: 'row',
  },
  Radio: {
    border: '2px solid var(--blue-50, #227596)',
    borderRadius: 8,
    padding: '7px 7px',
    margin: 'auto',
    marginTop: 10,
    color: '#003E3E',
    width: '100%',
    background: '#E6EEEE',
    fontSize: 17,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  RadioUnSelected: {
    border: '1px solid #A5A7A7',
    borderRadius: 8,
    padding: '7px 7px',
    margin: 'auto',
    marginTop: 10,
    color: '#003E3E',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
  RadioButton: {
    color: '#003E3E',
    '& .checked': {
      color: '#003E3E',
    },
  },
  formControl: {
    '& .span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1':
      {
        width: '-webkit-fill-available',
      },
  },
  hrow: {
    width: '100%',
    margin: 'auto',
  },
  hrowStyle: {
    border: '0.2px solid rgba(0,0,0,0.14)',
    height: '0.2px',
  },
  tabButton: {
    fontFamily: 'Inter',
    fontWeight: 700,
  },
  toggleIcon: {
    width: '70px',
    height: '80px',
    margin: '22px 20px 0',
  },
  tabLabel: {
    textTransform: 'none',
    fontSize: 18,
    fontFamily: 'Inter',
    fontWeight: 600,
    color: '#000',
  },
  tabLabelUnselected: {
    textTransform: 'none',
    fontSize: 18,
    fontFamily: 'Inter',
    fontWeight: 600,
  },
}));

interface EstimateStepProps {
  onNext: () => void;
  onBack: () => void;
  bookingLink: boolean;
  priceSelected: string;
  setPriceSelected: (newValue: string) => void;
  billingType: string;
  setBillingType: (newValue: string) => void;
  memberSelected: boolean;
  setMemberSelected: (newValue: boolean) => void;
}

type ExpandedState = {
  [key: string]: boolean;
};

const EstimateStep: React.FC<EstimateStepProps> = ({
  onNext,
  onBack,
  bookingLink,
  priceSelected,
  setPriceSelected,
  billingType,
  setBillingType,
  memberSelected,
  setMemberSelected,
}) => {
  const classes = useStyles();
  const isSm = useMediaQuery('(max-width: 1300px)');

  const [tabValue, setValue] = useState(0);
  const [toggleIcon, setToggleIcon] = useState(true);

  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const dispatch = useDispatch();

  const createInitialState = () => {
    const state: ExpandedState = {};
    if (appointment?.attributes.estimate) {
      return Object.keys(appointment?.attributes.estimate.services)
        .map((_, index) => ({ [`panel${index}`]: true }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});
    }
    return state;
  };

  const [expanded, setExpanded] = useState<ExpandedState>(createInitialState());

  const handleChange = (panel: string) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: !prevExpanded[panel],
    }));
  };

  const handleTabChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  const handleDeleteRepairProblem = (id: number, statusUpdate: string) => {
    const data = {
      estimate_service: {
        status: statusUpdate,
      },
    };

    updateEstimateServiceStatusV2(id, data).then((res) => {
      dispatch(setAppointment(res.data));
    });
  };

  const handleContinue = () => {
    if (!appointment) {
      return;
    }

    if (appointment.attributes.appointment_type === 'repair') {
      mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_ESTIMATE);
    } else {
      mixPanel(MIXPANEL_TRACK_FUNNEL_V2.NF_DIAGNOSIS);
    }

    onNext();
  };

  const getPrice = () => {
    let price = 0;

    const estimate = appointment?.attributes.estimate;

    if (estimate) {
      if (
        !appointment?.attributes.member_job ||
        appointment?.attributes.shop_network
      ) {
        price += estimate.total_price;
      } else {
        price += estimate.total_member_price;
      }
    }

    if (appointment?.attributes.appointment_type === 'diagnosis')
      price += appointment.attributes.diagnosis_price;

    price = price >= 0 ? price : 0;

    return price.toFixed(2);
  };

  const getCouponText = (coupon: ICoupon) => {
    if (coupon.code_type === 'agent') {
      let txt = 'Your service writer';

      if (coupon.display_name) {
        txt += `, ${coupon.display_name}, `;
      } else {
        txt += ' ';
      }

      txt += `applied a $${coupon.dollars_off} discount just for you.`;
      return txt;
    }

    return `Coupon code applied: ${coupon.code}`;
  };

  const getMemberPrice = () => {
    let price = 0;

    const estimate = appointment?.attributes.estimate;

    if (appointment?.attributes.appointment_type === 'diagnosis' && !estimate) {
      return (appointment.attributes.diagnosis_price * 0.9).toFixed(2);
    }

    if (estimate) {
      price += estimate.total_member_price;
    }

    if (appointment?.attributes.appointment_type === 'diagnosis')
      price += appointment.attributes.diagnosis_price;

    return price.toFixed(2);
  };

  const getDiff = () => {
    if (appointment?.attributes.appointment_type === 'diagnosis') {
      return appointment.attributes.diagnosis_fee;
    }

    return appointment?.attributes.estimate?.member_savings;
  };

  const handleCardClick = (value: string) => {
    if (value === 'member') {
      setMemberSelected(true);
    } else {
      setMemberSelected(false);
    }

    setPriceSelected(value);
  };

  const handleToggle = () => {
    if (toggleIcon) {
      setBillingType('monthly');
    } else {
      setBillingType('yearly');
    }
    setToggleIcon(!toggleIcon);
  };

  const randomNum = () => {
    return Math.floor(Math.random() * 4001) + 6000;
  };

  return (
    <div style={{ maxWidth: '600px' }}>
      {!bookingLink &&
        !(
          appointment?.attributes.appointment_type === 'repair' &&
          !appointment?.attributes.estimate
        ) && (
          <Button
            className={classes.buttonForward}
            style={{
              color: '#005959',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={() => {
              onBack();
            }}
          >
            <ArrowBack
              style={{
                width: 20,
                height: 20,
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            />{' '}
            <Typography
              style={{
                margin: 'auto 0 auto 10px',
                fontSize: 16,
                fontFamily: 'Inter',
              }}
            >
              Back
            </Typography>
          </Button>
        )}
      {appointment?.attributes.appointment_type === 'repair' &&
      !appointment?.attributes.estimate ? (
        <GetBackStep />
      ) : (
        <Card
          className={classes.card}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',

            width: 'fit-content',
            background: '#F3F3F3',
          }}
          elevation={0}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: isSm ? 0 : 20,
            }}
          >
            {appointment?.attributes.appointment_type === 'diagnosis' && (
              <Accordion
                style={{
                  background: '#fff',
                  borderRadius: 8,
                  border: '1px solid #005959',
                  marginBottom: 20,
                  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
                }}
                elevation={1}
                expanded
              >
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      style={{
                        margin: '10px',
                        fontFamily: 'Inter',
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Diagnosis
                    </Typography>
                  </div>

                  <Box style={{ margin: '10px' }}>
                    {appointment.attributes.diagnosis_fee && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 10,
                          alignItems: 'center',
                        }}
                      >
                        <CheckCircle
                          style={{ color: '#005959', height: 25, width: 25 }}
                        />
                        <Typography
                          style={{
                            margin: '10px',
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        >
                          Comprehensive diagnosis of your vehicle
                        </Typography>
                      </div>
                    )}

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                        alignItems: 'center',
                      }}
                    >
                      <CheckCircle
                        style={{ color: '#005959', height: 25, width: 25 }}
                      />
                      <Typography
                        style={{
                          margin: '10px',
                          fontFamily: 'Inter',
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        Option to pay for repairs in installments later
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                        alignItems: 'center',
                      }}
                    >
                      <CheckCircle
                        style={{ color: '#005959', height: 25, width: 25 }}
                      />
                      <Typography
                        style={{
                          margin: '10px',
                          fontFamily: 'Inter',
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        Our technician will come to you
                      </Typography>
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}

            <div>
              {appointment?.attributes.estimate && (
                <>
                  <div
                    style={{
                      maxHeight: 400,
                      overflowY: 'scroll',
                      marginBottom: 20,
                    }}
                  >
                    {Object.keys(appointment.attributes.estimate.services).map(
                      (service, index) => (
                        <Accordion
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          style={{
                            background: '#fff',

                            marginRight: 10,
                            borderRadius: 8,
                            marginBottom: 20,
                            boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
                          }}
                          className={classes.accordion}
                          elevation={1}
                          expanded={expanded[`panel${index}`] || false}
                          onChange={() => handleChange(`panel${index}`)}
                        >
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: 20,
                                width: '100%',
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: 'Inter',
                                  textDecoration:
                                    appointment.attributes.estimate?.services[
                                      service
                                    ].status === 'rejected'
                                      ? 'line-through'
                                      : 'none',
                                  fontSize: 16,
                                  fontWeight: 700,
                                }}
                              >
                                {service}
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: 'Inter',
                                  textDecoration:
                                    appointment.attributes.estimate?.services[
                                      service
                                    ].status === 'rejected'
                                      ? 'line-through'
                                      : 'none',
                                  fontSize: 16,
                                  fontWeight: 700,
                                }}
                              >
                                $
                                {Number(
                                  appointment.attributes.estimate?.services[
                                    service
                                  ].total_price
                                ).toFixed(2)}
                              </Typography>
                            </div>
                          </AccordionSummary>
                          {appointment &&
                            appointment.attributes.estimate?.services[service]
                              .customer_to_supply_parts && (
                              <Typography
                                style={{
                                  fontSize: 12,
                                  color: 'grey',
                                  fontFamily: 'Inter',
                                  fontStyle: 'Italic',
                                  marginLeft: 10,
                                }}
                              >
                                * Customer supplies parts
                              </Typography>
                            )}
                          <div
                            style={{
                              marginLeft: 10,
                              marginRight: 10,
                              borderTop: '0.2px solid lightgrey',
                            }}
                          />
                          <AccordionDetails>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {appointment.attributes.estimate?.services[
                                service
                              ].parts.map((part) => {
                                return (
                                  <div>
                                    <Typography
                                      style={{
                                        fontSize: 16,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        fontFamily: 'Inter',
                                      }}
                                    >
                                      {part.name} x({part.quantity}):&nbsp; $
                                      {part.unit_price}{' '}
                                    </Typography>
                                  </div>
                                );
                              })}

                              <Typography
                                style={{
                                  fontSize: 16,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  fontFamily: 'Inter',
                                }}
                              >
                                Labor :&nbsp; $
                                {
                                  appointment.attributes.estimate?.services[
                                    service
                                  ].labor_price
                                }{' '}
                              </Typography>
                            </div>
                            {appointment.attributes.estimate &&
                              Object.keys(
                                appointment.attributes.estimate.services
                              ).length > 1 && (
                                <Button
                                  onClick={() => {
                                    if (appointment.attributes.estimate) {
                                      const serviceToUpdate =
                                        appointment.attributes.estimate
                                          ?.services[service];

                                      handleDeleteRepairProblem(
                                        serviceToUpdate.id,
                                        serviceToUpdate.status === 'rejected'
                                          ? 'approved'
                                          : 'rejected'
                                      );
                                    }
                                  }}
                                  style={{
                                    marginLeft: 'auto',
                                    color:
                                      appointment.attributes.estimate?.services[
                                        service
                                      ].status === 'rejected'
                                        ? 'red'
                                        : 'green',
                                    padding: 0,
                                  }}
                                >
                                  {appointment.attributes.estimate?.services[
                                    service
                                  ].status === 'rejected' ? (
                                    <ToggleOff />
                                  ) : (
                                    <ToggleOn />
                                  )}
                                </Button>
                              )}
                          </AccordionDetails>
                        </Accordion>
                      )
                    )}
                  </div>
                </>
              )}
            </div>

            {appointment &&
            !appointment?.attributes.member_type &&
            !appointment.attributes.fleet_job &&
            !appointment.attributes.shop_network ? (
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <Typography
                  style={{
                    fontSize: 16,
                    marginBottom: 10,
                    fontFamily: 'Inter',
                    fontWeight: 700,
                  }}
                >
                  Choose your service price
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    marginBottom: 20,
                    fontFamily: 'Inter',
                  }}
                >
                  Become a member and unlock savings.
                </Typography>

                <div className="plan-cards">
                  <Card
                    onClick={() => handleCardClick('non-member')}
                    className={
                      priceSelected === 'non-member'
                        ? classes.Radio
                        : classes.RadioUnSelected
                    }
                    style={{ paddingBottom: 5, paddingTop: 10 }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              gap: 10,
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={
                                priceSelected === 'non-member'
                                  ? radioselected
                                  : radio
                              }
                              alt="Credit Card"
                              style={{ width: 20, height: 20 }}
                            />

                            <Typography
                              style={{ fontSize: 16, fontFamily: 'Inter' }}
                            >
                              Regular Price
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontSize: 16,
                            fontWeight: 700,
                          }}
                        >
                          ${getPrice()}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>

                  <Box display="flex" style={{ margin: '20px 0' }}>
                    <div className={classes.hrow}>
                      <hr className={classes.hrowStyle} />
                    </div>
                    <div style={{ margin: '0 15px' }}>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: 'Inter',
                        }}
                      >
                        Or
                      </Typography>
                    </div>
                    <div className={classes.hrow}>
                      <hr className={classes.hrowStyle} />
                    </div>
                  </Box>

                  <Card
                    onClick={() => handleCardClick('member')}
                    className={
                      priceSelected === 'member'
                        ? classes.Radio
                        : classes.RadioUnSelected
                    }
                  >
                    <div
                      style={{
                        background: '#003E3E',
                        padding: '10px 10px 5px 10px',
                        borderBottomLeftRadius: '10px',
                        width: 'fit-content',
                        marginLeft: 'auto',
                        marginTop: -10,
                        marginRight: -10,
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontSize: 12,
                          color: '#FFFFFF',
                        }}
                      >
                        {' '}
                        10% OFF
                      </Typography>
                    </div>

                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              gap: 10,
                              marginTop: -25,
                            }}
                          >
                            <img
                              src={
                                priceSelected === 'member'
                                  ? radioselected
                                  : radio
                              }
                              alt="Credit Card"
                              style={{ width: 20, height: 20, marginTop: 10 }}
                            />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: 10,
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: isSm ? 16 : 16,
                                  fontFamily: 'Inter',
                                  fontWeight: 700,
                                }}
                              >
                                Goodhood Member Price
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: isSm ? 12 : 16,
                                  fontFamily: 'Inter',
                                  color: '#4C4F4E',
                                }}
                              >
                                {appointment?.attributes.appointment_type ===
                                'diagnosis'
                                  ? 'Save 10% on repairs after your diagnosis'
                                  : `Save ${getDiff()} on your service today`}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontSize: 16,
                            marginBottom: -20,
                            fontWeight: 700,
                          }}
                        >
                          ${getMemberPrice()}
                        </Typography>
                      </div>
                      <div className={classes.hrow}>
                        <hr className={classes.hrowStyle} />
                      </div>
                      <Box display="flex" style={{ justifyContent: 'center' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: isSm ? 16 : 18,
                              fontFamily: 'Inter',
                              fontWeight: 600,
                              marginTop: 15,
                            }}
                          >
                            Goodhood Membership
                          </Typography>
                          <Box
                            display="flex"
                            style={{
                              width: '100%',
                              marginRight: isSm ? 10 : 30,
                              justifyContent: 'end',
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 13,
                                fontFamily: 'Inter',
                                color: '#337A7A',
                              }}
                            >
                              Save 20%
                            </Typography>
                          </Box>
                          <Box display="flex" style={{ marginLeft: 10 }}>
                            <Typography
                              style={{ fontSize: 16, fontFamily: 'Inter' }}
                            >
                              6 months
                            </Typography>

                            <Button
                              style={{
                                padding: 0,
                                background: 'none',
                                height: 0,
                              }}
                              onClick={handleToggle}
                            >
                              {toggleIcon && (
                                <ToggleOn className={classes.toggleIcon} />
                              )}
                              {!toggleIcon && (
                                <ToggleOff
                                  className={classes.toggleIcon}
                                  style={{ color: '#005959' }}
                                />
                              )}
                            </Button>

                            <Typography
                              style={{
                                fontSize: isSm ? 16 : 16,
                                fontFamily: 'Inter',
                                fontWeight: 700,
                              }}
                            >
                              Yearly
                            </Typography>
                            <Box
                              style={{ display: 'flex', paddingLeft: '10px' }}
                            >
                              <img
                                alt="arrow"
                                style={{ width: 22, height: 22 }}
                                src={arrowup}
                              />
                            </Box>
                          </Box>

                          <Box
                            display="flex"
                            style={{
                              justifyContent: 'center',
                              marginBottom: 20,
                              marginTop: 20,
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: 'Inter',
                                fontSize: 24,
                                fontWeight: 700,
                              }}
                            >
                              {!toggleIcon ? '$90' : '$290'}
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: 'Inter',
                                fontSize: 15,
                                marginTop: 9,
                              }}
                            >
                              {!toggleIcon ? '/6 months' : '/year'}
                            </Typography>
                          </Box>
                        </div>
                      </Box>

                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        <Tab
                          className={classes.tabButton}
                          label={
                            <span
                              className={
                                tabValue === 0
                                  ? classes.tabLabel
                                  : classes.tabLabelUnselected
                              }
                            >
                              Benefits
                            </span>
                          }
                        />
                        <Tab
                          className={classes.tabButton}
                          label={
                            <span
                              className={
                                tabValue === 1
                                  ? classes.tabLabel
                                  : classes.tabLabelUnselected
                              }
                            >
                              Why Join?
                            </span>
                          }
                        />
                      </Tabs>
                      <div
                        style={{ marginTop: '-9px' }}
                        className={classes.hrow}
                      >
                        <hr className={classes.hrowStyle} />
                      </div>
                    </CardContent>
                    {tabValue === 0 && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          maxWidth: 425,
                          gap: 15,
                          margin: '10px 0 20px 15px',
                        }}
                      >
                        <Box display="flex">
                          {' '}
                          <img
                            src={waterDrop}
                            alt="Credit Card"
                            style={{ width: 22, height: 22 }}
                          />{' '}
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: 'Inter',
                              marginLeft: 10,
                            }}
                          >
                            Free Unlimited Oil Changes
                          </Typography>
                        </Box>
                        <Box display="flex">
                          {' '}
                          <img
                            src={discount}
                            alt="Credit Card"
                            style={{ width: 22, height: 22 }}
                          />{' '}
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: 'Inter',
                              marginLeft: 10,
                            }}
                          >
                            10% off All Repairs with a Price Match Guarantee
                          </Typography>
                        </Box>
                        <Box display="flex">
                          {' '}
                          <img
                            src={phonelinkring}
                            alt="Credit Card"
                            style={{ width: 22, height: 22 }}
                          />{' '}
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: 'Inter',
                              marginLeft: 10,
                            }}
                          >
                            Real-time Maintenance Alerts on our Mobile App
                          </Typography>
                        </Box>
                        <Box display="flex">
                          {' '}
                          <img
                            src={vector}
                            alt="Credit Card"
                            style={{ width: 22, height: 22 }}
                          />{' '}
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: 'Inter',
                              marginLeft: 10,
                            }}
                          >
                            Remote Check Engine Light support
                          </Typography>
                        </Box>
                        <Box display="flex">
                          {' '}
                          <img
                            src={autotowing}
                            alt="Credit Card"
                            style={{ width: 22, height: 22 }}
                          />{' '}
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: 'Inter',
                              marginLeft: 10,
                            }}
                          >
                            Free National Roadside Assistance
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <Typography
                            style={{
                              fontSize: 16,
                              fontFamily: 'Inter',
                              marginTop: 10,
                            }}
                          >
                            Join{' '}
                            <span style={{ fontWeight: 600 }}>
                              +{randomNum()}
                            </span>{' '}
                            other members in your area
                          </Typography>
                        </Box>
                      </div>
                    )}
                    {tabValue === 1 && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '30px 15px 15px',
                          maxWidth: 425,
                        }}
                      >
                        <Box
                          display="flex"
                          style={{ justifyContent: 'space-between' }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 15,
                              maxWidth: 250,
                              marginLeft: 25,
                            }}
                          >
                            {' '}
                            <img
                              src={semiColumn}
                              alt="Credit Card"
                              style={{ width: 22, height: 22 }}
                            />{' '}
                            <Typography
                              style={{
                                fontSize: isSm ? 14 : 16,
                                fontFamily: 'Inter',
                              }}
                            >
                              Replaced AAA with Goodhood. Same benefits plus
                              free oil changes & a mechanic who comes to me.
                            </Typography>
                            <Typography
                              style={{
                                fontSize: isSm ? 14 : 16,
                                fontFamily: 'Inter',
                                fontWeight: 600,
                              }}
                            >
                              Tiffany in Plano, TX{' '}
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              height: '190px',
                              width: '135px',
                              marginRight: 15,
                            }}
                          >
                            <img
                              src={women}
                              alt="women"
                              style={{ objectFit: 'contain' }}
                            />
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          style={{
                            justifyContent: 'space-between',
                            borderRadius: '8px',
                            padding: '0 20px',
                            marginTop: '3px',
                            background: '#E6EEEE',
                          }}
                        >
                          <Box style={{ height: '160px', width: '115px' }}>
                            <img
                              src={money}
                              alt="money"
                              style={{ objectFit: 'contain' }}
                            />
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              gap: 15,
                              marginLeft: isSm ? 0 : 20,
                            }}
                          >
                            <Box display="flex">
                              <Typography
                                style={{
                                  fontFamily: 'Inter',
                                  fontSize: isSm ? 20 : 24,
                                  fontWeight: 700,
                                }}
                              >
                                Save $248
                              </Typography>
                              <Typography
                                style={{
                                  fontFamily: 'Inter',
                                  fontSize: 15,
                                  marginTop: isSm ? 5 : 9,
                                }}
                              >
                                /year
                              </Typography>
                            </Box>
                            <Typography
                              style={{
                                fontSize: 16,
                                fontFamily: 'Inter',
                              }}
                            >
                              We promise to save you a good chunk with all our
                              benefits
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                    )}
                  </Card>

                  <div
                    style={{
                      padding: 20,
                      background: '#EDF5E0',
                      marginTop: 20,
                      borderRadius: 12,
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontSize: 14,
                      }}
                    >
                      Pay nothing today. You won&apos;t be charged until the day
                      of service.
                    </Typography>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {appointment?.attributes.coupon_used && (
              <div
                style={{
                  background: '#E3F2F9',
                  border: '1px solid #48ADD5',
                  display: 'flex',
                  maxWidth: 400,
                  gap: 20,
                  padding: '10px 20px',
                  borderRadius: 12,
                  marginBottom: 20,
                }}
              >
                <img
                  src={giftbox}
                  alt="Credit Card"
                  style={{ width: 20, height: 20, marginTop: 10 }}
                />

                <div style={{ display: 'flex' }}>
                  <Typography
                    style={{
                      fontSize: 16,
                      color: '#1A5B74',
                      fontFamily: 'Inter',
                    }}
                  >
                    {getCouponText(appointment.attributes.coupon_used)}
                  </Typography>
                </div>
              </div>
            )}

            {isSm && <ReferralBox />}
            {isSm && (
              <InvoiceMobile
                onNext={handleContinue}
                nonMember={priceSelected === 'non-member'}
                changedPrice={
                  priceSelected === 'member' ? getMemberPrice() : getPrice()
                }
              />
            )}

            <div
              style={{
                display: 'flex',
                maxWidth: 400,
                gap: 10,
                borderRadius: 12,
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              {' '}
              <img
                src={shield}
                alt="Credit Card"
                style={{ width: 20, height: 20 }}
              />{' '}
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: 'Inter',
                }}
              >
                2 year service warranty on all services
              </Typography>
            </div>

            {appointment?.attributes.appointment_type !== 'diagnosis' && (
              <div
                style={{
                  display: 'flex',
                  maxWidth: 400,
                  gap: 10,
                  borderRadius: 12,
                  marginBottom: 20,
                  alignItems: 'center',
                }}
              >
                {' '}
                <img
                  src={installment}
                  alt="Credit Card"
                  style={{ width: 20, height: 20 }}
                />{' '}
                <Typography
                  style={{
                    fontSize: 16,

                    fontFamily: 'Inter',
                  }}
                >
                  Option to pay for repairs in installments later
                </Typography>
              </div>
            )}
          </div>

          {!isSm && (
            <Button
              onClick={handleContinue}
              style={{
                background: '#005959',
                marginTop: '35px',
                marginLeft: '10px',
                color: '#fff',
                height: '56px',
                fontSize: '16px',
              }}
              className={classes.buttonForward}
              variant="contained"
              size="large"
            >
              Continue &nbsp;
              <ArrowForward />
            </Button>
          )}
        </Card>
      )}
    </div>
  );
};

export default EstimateStep;
