import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Hidden,
  Link,
  makeStyles,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import SvgLogo from 'src/assets/logo.svg';
import phoneIcon from 'src/assets/phone-icon.svg';
import usericon from 'src/assets/user-icon.svg';
import emailIcon from 'src/assets/email-icon.svg';
import LoginSideImg from 'src/assets/LoginPageImg.png';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import { visuallyHidden } from '@mui/utils';
import {
  ButtonForward,
  InputWithStatus,
  Image,
  SelectWithStatus,
} from 'src/components/atoms';
import { Email, Height, LockOpen, Phone } from '@material-ui/icons';
import { signUp } from 'src/api/auth';
import { ResponseSignup } from 'src/types';
import { logout, setAuthCode, setAuthToken } from 'src/store/actions';
import { MODE_LOGIN, URLS } from 'src/utils/consts';
import { dataLayerLogin } from 'src/utils/ads';
import { CONTACT_NUMBER, FLEET_CONTACT_NUMBER } from 'src/utils/config';
import GoogleLoginButton from '../Login/components/GoogleLoginButton';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    background: '#fff',
    overflowY: 'hidden',
    fontFamily: 'Inter',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
  },
  showcaseImg: {
    width: '100%',
    height: '100%',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '30px',
  },
  appleIcon: {
    width: '190px',
    height: '58px',
  },

  error: {
    color: 'red',
  },
  success: {
    color: theme.palette.primary.main,
  },
  input: {
    width: '100%',
    zIndex: 100,
    border: 'none',
    marginLeft: '-2px',
    marginBottom: '3px',
  },
  halfWidthInputsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    // Media Query for mobile screens
    '@media (max-width: 600px)': {
      display: 'block',
    },
  },
  halfWidthInput: {
    width: 'calc(50% - 5px)',
    zIndex: 100,
    border: 'none',
    margin: '5px 0',
    minWidth: 324,
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    padding: '12px',
    zIndex: 100,
    border: '1px',
    borderRadius: '8px',
    marginTop: '5px',
    marginBottom: '20px !important',
    '& .MuiButton-label': {
      justifyContent: 'center',
    },
  },
  appBar: {
    background: '#fff',
    color: 'black',
    boxShadow: 'none',
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logoImage: {
    display: 'flex',
    width: '177.6px',
    height: '32px',
    padding: '0.856px 0.704px 0.793px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,

    // Media Query for mobile screens
    '@media (max-width: 600px)': {
      width: '134px',
      height: '24px',
      padding: '0.642px 0.531px 0.595px 0px',
    },
  },
  imageCenterAlign: {
    flexGrow: 1,
    textAlign: 'center',
  },
  phone: {
    marginRight: '20px',
    color: '#000',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 500,
  },
  gridButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  iconImg: {
    width: '20px',
    marginTop: '4px',
    marginRight: '10px',
  },
  customIconBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    '& .MuiButton-label': {
      justifyContent: 'center',
    },
  },

  createAccountBlock: {
    borderTop: '1px solid #E5E5E5',
    marginTop: '20px',
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const SignUp = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [signUpError, setSignUpError] = useState('');
  const [signingUp, setSigningUp] = useState<boolean>(false);

  const hideError = () => {
    setTimeout(() => {
      setSignUpError('');
    }, 5000);
  };

  const [data, setData] = useState({
    mode: MODE_LOGIN.PHONE,
    email: '',
    fullName: '',
    businessName: '',
    businessAddress: '',
    fleetSize: '',
    fleetType: '',
    password: '',
    phone: '',
    fleet: true,
  });

  const handleInputChange = (key: string, val: string) => {
    setData((state) => ({
      ...state,
      [key]: val,
    }));
  };

  const handleForward = async () => {
    setSigningUp(true);
    const signUpParams = {
      user: {
        email: data.email,
        phone: data.phone,
        name: data.fullName,
        business_name: data.businessName,
        business_address: data.businessAddress,
        fleet_size: data.fleetSize,
        fleet_type: data.fleetType,
        fleet: data.fleet,
      },
    };
    try {
      const response: ResponseSignup = await signUp(signUpParams).finally(() =>
        setSigningUp(false)
      );
      if (
        response &&
        response.auth_token &&
        response.user &&
        response.user.id
      ) {
        dispatch(
          setAuthToken(
            response.auth_token,
            response.user.id,
            response.user.email
          )
        );
        dataLayerLogin(response);

        history.push(URLS.DASHBOARD);
      }
    } catch (error: any) {
      setSignUpError(error.response.data.errors.join(' '));
      hideError();
    }
  };

  const handleCall = () => {
    window.location.href = `tel:${CONTACT_NUMBER?.replace('-', '')}`;
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={0} className={classes.mainContainer}>
        <Grid item md={6} spacing={0}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Image
              className={classes.showcaseImg}
              src={LoginSideImg}
              alt="showcase"
              lazy={false}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} spacing={0}>
          <AppBar position="static" className={classes.appBar}>
            <Toolbar>
              <a href="/" title="thefront">
                <Image
                  className={classes.logoImage}
                  src={SvgLogo}
                  alt="thefront"
                  lazy={false}
                />
              </a>

              <Hidden smDown>
                <div style={{ marginLeft: 'auto' }}>
                  <div style={{ display: 'flex' }}>
                    <Typography
                      style={{
                        fontSize: '16px',
                        color: '#1F2322',
                        marginTop: '3px',
                      }}
                    >
                      Call or Text our Fleet line
                    </Typography>
                    <Button
                      className={classes.phone}
                      startIcon={<CallOutlinedIcon />}
                      onClick={handleCall}
                      color="inherit"
                    >
                      {FLEET_CONTACT_NUMBER}
                    </Button>
                  </div>
                </div>
              </Hidden>
            </Toolbar>
          </AppBar>

          <div className={classes.fieldContainer}>
            <Box component="form" sx={{ mt: 3, width: '100%' }}>
              <Typography
                variant="h4"
                style={{ fontSize: '25px', color: '#1F2322' }}
              >
                Create an account
              </Typography>
              <>
                <Typography
                  align="left"
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '15px',
                    color: '#BEBBB8',
                  }}
                >
                  Enter your details to proceed
                </Typography>
                <InputWithStatus
                  className={classes.input}
                  noCheck
                  placeholder="Full Name"
                  value={data?.fullName}
                  valueChanged={(val: string) =>
                    handleInputChange('fullName', val)
                  }
                />
                <div className={classes.halfWidthInputsContainer}>
                  <InputWithStatus
                    className={classes.halfWidthInput}
                    noCheck
                    placeholder="Email Address"
                    value={data?.email}
                    valueChanged={(val: string) =>
                      handleInputChange('email', val)
                    }
                    inputMode="email"
                  />
                  <InputWithStatus
                    className={classes.halfWidthInput}
                    noCheck
                    placeholder="Phone Number"
                    value={data?.phone}
                    valueChanged={(val: string) =>
                      handleInputChange('phone', val)
                    }
                    inputMode="numeric"
                  />
                </div>
                <div className={classes.halfWidthInputsContainer}>
                  <InputWithStatus
                    className={classes.halfWidthInput}
                    noCheck
                    placeholder="Business Name"
                    value={data?.businessName}
                    valueChanged={(val: string) =>
                      handleInputChange('businessName', val)
                    }
                    inputMode="tel"
                  />
                  <InputWithStatus
                    className={classes.halfWidthInput}
                    noCheck
                    placeholder="Business Address"
                    value={data?.businessAddress}
                    valueChanged={(val: string) =>
                      handleInputChange('businessAddress', val)
                    }
                    inputMode="tel"
                  />
                </div>
                <div className={classes.halfWidthInputsContainer}>
                  <InputWithStatus
                    className={classes.halfWidthInput}
                    noCheck
                    placeholder="Fleet Size (how many vehicles)"
                    value={data?.fleetSize}
                    valueChanged={(val: string) =>
                      handleInputChange('fleetSize', val)
                    }
                    inputMode="numeric"
                  />
                  <div>
                    <SelectWithStatus
                      value={data?.fleetType}
                      className={classes.halfWidthInput}
                      valueChanged={(value) =>
                        handleInputChange('fleetType', value)
                      }
                      label="Fleet type"
                      items={{
                        Gas: 'Gas',
                        Diesel: 'Diesel',
                        Mixed: 'Mixed Fleet',
                      }}
                    />
                  </div>
                </div>

                <ButtonForward
                  className={classes.buttons}
                  key="forward"
                  title="Create Account"
                  size="large"
                  onClickHandler={handleForward}
                  disabled={!data.email || !data.phone}
                />
              </>

              <Box className="" style={{ margin: '10px 0', width: '100%' }}>
                {signingUp ? (
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <Grid container spacing={0} className={classes.gridButtons}>
                      {/* <Grid item md={6} spacing={0}> */}
                      <GoogleLoginButton setLoggingIn={setSigningUp} />
                      {/* </Grid> */}
                    </Grid>
                  </>
                )}
              </Box>

              {signUpError !== '' ? (
                <Typography
                  style={{
                    textAlign: 'center',
                    margin: '10px',
                    color: '#ff0000',
                  }}
                >
                  {signUpError}
                </Typography>
              ) : (
                <></>
              )}
            </Box>

            <Box className={classes.createAccountBlock}>
              <Typography
                variant="body2"
                style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                  marginTop: '10px',
                  color: '#1F2322',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
              >
                Existing User?
              </Typography>
              <Button
                variant="outlined"
                color="default"
                className={classes.customIconBtn}
                style={{ borderColor: '#005959' }}
                onClick={() => history.push(URLS.LOGIN_FLEET)}
              >
                <Image
                  className={classes.iconImg}
                  src={usericon}
                  alt="icon"
                  lazy={false}
                />
                login
              </Button>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUp;
